import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import gsap from 'gsap';

import { APP_LINKS } from '../utils/constants';

import quasark_svg from '../assets/images/quasark.svg';
import app_store_svg from '../assets/images/social/download_app_store.svg';
import google_play_svg from '../assets/images/social/download_goole_play.svg';
import phone_1 from '../assets/images/phones/phone_1_new.webp';
import phone_2 from '../assets/images/phones/phone_2_new.webp';
import phone_3 from '../assets/images/phones/phone_3.webp';

function UnlockThePower() {
  const isMobile = useMediaQuery('(max-width:1000px)');
  const isLess600 = useMediaQuery('(max-width:600px)');
  const isLess1550 = useMediaQuery('(max-width:1550px)');
  const theme = useTheme();
  const buttonRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const [mobAnimationStart, setMobAnimationStart] = useState<null | number>(null);
  const [showMobileAnimation, setShowMobileAnimation] = useState(false);
  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (buttonRef.current && textRef.current && isLess600) {
      const start = buttonRef.current.getBoundingClientRect().y + buttonRef.current.offsetHeight;
      const finish = textRef.current.offsetTop + textRef.current.offsetHeight;
      setMobAnimationStart(finish - start - 50);
    }
  }, [buttonRef.current?.offsetTop, textRef.current?.offsetTop, isLess600]);

  let timer1 = setTimeout(() => {
    setShowMobileAnimation(true);
  }, 500);

  useEffect(() => {
    return () => {
      clearTimeout(timer1);
    };
  }, [showMobileAnimation]);

  useEffect(() => {
    // Create a gsap.context bound to this component
    const ctx = gsap.context(() => {
      // Scoped animations within this context
      if (mobAnimationStart && showMobileAnimation) {
        gsap.to('.mob_phone_animation', {
          y: 0,
          scrollTrigger: {
            start: '0px top',
            end: '1900px 900px',
            scrub: true,
            // markers: true,
            id: 'move_phone_mob',
          },
        });
      }
    }, componentRef); // Pass the ref of the parent container

    // Cleanup when the component unmounts
    return () => ctx.revert();
  }, [mobAnimationStart, showMobileAnimation]);

  useEffect(() => {
    // Create a gsap.context bound to this component
    const ctx = gsap.context(() => {
      // Scoped animations within this context
      if (isMobile) {
        if (!isLess600) {
          gsap.to('.mob_phone_animation', {
            y: 0,
            scale: 1,
            scrollTrigger: {
              // trigger: ".mob_phone_animation",
              start: '0px top',
              end: '1900px 900px',
              scrub: true,
              // markers: true,
              id: 'move_phone_mob',
            },
          });
        }
      } else {
        gsap.to('.desktop_phone_animation', {
          y: 0,
          scale: 1,
          scrollTrigger: {
            trigger: '.desktop_phone_animation_wrapper',
            start: '-90px',
            end: `bottom ${window.innerHeight / 1.3}px`,
            scrub: true,
            // markers: true,
            id: 'move_phone_desktop',
          },
        });
        gsap.to('.desktop_left_phone_animation', {
          x: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: '.desktop_phone_animation_wrapper',
            start: '200px',
            end: `bottom ${window.innerHeight / 1.3}px`,
            scrub: true,
            // markers: true,
            id: 'move_left_phone_desktop',
          },
        });
        gsap.to('.desktop_right_phone_animation', {
          x: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: '.desktop_phone_animation_wrapper',
            start: '200px',
            end: `bottom ${window.innerHeight / 1.3}px`,
            scrub: true,
            // markers: true,
            id: 'move_right_phone_desktop',
          },
        });

        gsap.to('.desktop_text_animation', {
          y: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: '.desktop_phone_animation_wrapper',
            start: `${window.innerHeight + 200}px`,
            end: `${window.innerHeight + 600}px`,
            scrub: true,
            // markers: true,
            id: 'move_text_desktop',
          },
        });
      }
    }, componentRef); // Pass the ref of the parent container

    // Cleanup when the component unmounts
    return () => ctx.revert();
  }, [isMobile, isLess600]);

  return (
    <Box
      ref={componentRef}
      sx={{
        width: '100%',
        maxWidth: '1656px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '1656px',
        }}
      >
        <Box
          sx={{
            padding: '0 20px',
            position: 'absolute',
            top: '152px',
            [theme.breakpoints.down(1000)]: {
              position: 'absolute',
              top: '144px',
            },
            [theme.breakpoints.down(600)]: {
              position: 'absolute',
              top: '114px',
            },
          }}
        >
          <img width={'100%'} src={quasark_svg} alt="logo"></img>
          {isLess600 && (
            <Box
              ref={buttonRef}
              sx={{
                marginTop: '15px',
                width: '100%',
                gap: '15px',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Avenir500',
                  color: 'var(--white)',
                  fontSize: '20px',
                  lineHeight: 'normal',
                }}
              >
                Your ultimate crypto toolkit
              </Typography>
              <Button
                sx={{
                  fontFamily: 'Avenir600',
                  fontSize: '16px',
                  lineHeight: 'normal',
                  color: 'var(--white)',
                  textTransform: 'none',
                  padding: '15px 30px',
                  borderRadius: '10px',
                  backgroundColor: 'var(--gold)',
                  transition: 'all 0.3s ease',
                  letterSpacing: 'normal',
                  '&:hover': {
                    backgroundColor: 'var(--gold)',
                  },
                }}
                onClick={() => window.open(APP_LINKS.ONE_LINK, '_blank')}
                variant="contained"
              >
                Download the app
              </Button>
            </Box>
          )}
          {isMobile && !isLess600 && (
            <>
              <Typography
                sx={{
                  fontFamily: 'Avenir400',
                  color: 'var(--white)',
                  fontSize: '24px',
                  lineHeight: 'normal',
                  marginTop: '40px',
                  padding: '0 20px',
                  textAlign: 'center',
                }}
              >
                World’s first anonymous crypto wallet with exclusive community-driven features. Powered by{' '}
                <span style={{ fontFamily: 'Avenir600', color: 'var(--white)' }}>QSRK token.</span>
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: '20px',
                  marginTop: '40px',
                  justifyContent: 'center',
                  '& img': {
                    transition: 'all 0.3s ease',
                    cursor: 'pointer',
                    '&:hover': {
                      opacity: 0.8,
                    },
                  },
                }}
              >
                <img
                  onClick={() => {
                    window.open(APP_LINKS.APP_STORE, '_blank');
                  }}
                  src={app_store_svg}
                  alt="app store"
                ></img>
                <img
                  onClick={() => {
                    window.open(APP_LINKS.GOOGLE_PLAY, '_blank');
                  }}
                  src={google_play_svg}
                  alt="google play"
                ></img>
              </Box>
            </>
          )}
        </Box>
        {isMobile ? (
          <>
            <Box
              sx={{
                textAlign: 'center',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Avenir400',
                  color: 'var(--white)',
                  fontSize: '24px',
                  padding: '0 40px',
                  lineHeight: 'normal',
                  [theme.breakpoints.down(1000)]: {
                    display: 'none',
                  },
                  [theme.breakpoints.down(600)]: {
                    display: 'inherit',
                    fontFamily: 'Avenir400',
                    color: 'var(--grey4)',
                    fontSize: '20px',
                    padding: '0 20px',
                    paddingTop: 'calc(100vh - 90px)',
                  },
                }}
              >
                World’s first anonymous crypto wallet with exclusive community-driven features. Powered by{' '}
                <span style={{ fontFamily: 'Avenir600', color: 'var(--white)' }}>QSRK token.</span>
              </Typography>
            </Box>
          </>
        ) : (
          <Box
            sx={{
              padding: '0 20px',
              position: 'absolute',
              top: '152px',
              marginTop: 'calc(100vw / 6.7 + 80px)',
              width: '100%',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Avenir400',
                color: 'var(--white)',
                fontSize: '24px',
                maxWidth: '30%',
              }}
            >
              {isLess1550 ? (
                <> World’s first anonymous crypto wallet with exclusive community-driven features.</>
              ) : (
                <>
                  World’s first anonymous crypto wallet with
                  <br />
                  exclusive community-driven features.
                </>
              )}
              <br />
              <br />
              Powered by <span style={{ fontFamily: 'Avenir600' }}>QSRK token.</span>
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '20px',
                marginTop: '40px',
                '& img': {
                  transition: 'all 0.3s ease',
                  cursor: 'pointer',
                  '&:hover': {
                    opacity: 0.8,
                  },
                },
              }}
            >
              <img
                onClick={() => {
                  window.open(APP_LINKS.APP_STORE, '_blank');
                }}
                src={app_store_svg}
                alt="app store"
              ></img>
              <img
                onClick={() => {
                  window.open(APP_LINKS.GOOGLE_PLAY, '_blank');
                }}
                src={google_play_svg}
                alt="google play"
              ></img>
            </Box>
          </Box>
        )}
      </Box>
      {isMobile ? (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box ref={textRef}>
            <Typography
              sx={{
                fontFamily: 'Avenir600',
                fontSize: '36px',
                color: 'var(--white)',
                textAlign: 'center',
                maxWidth: '380px',
                margin: '0 20px',
                marginTop: '30px',
                [theme.breakpoints.down(1000)]: {
                  marginTop: 'calc(100vh - 90px)',
                  marginBottom: '60px',
                },
                [theme.breakpoints.down(600)]: {
                  marginTop: '30px',
                  marginBottom: '40px',
                },
              }}
            >
              Unlock the real power of the blockchain
            </Typography>
          </Box>
          <Box
            sx={{
              '& p': {
                fontFamily: 'Avenir600',
                fontSize: '24px',
                color: 'var(--white)',
                textAlign: 'center',
                marginTop: '20px',
              },
            }}
          >
            <Box
              className="mob_phone_animation"
              sx={{
                // padding: '20px 40px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                [theme.breakpoints.down(1000)]: {
                  transform: 'translateY(calc(-100vh + 270px +  100vw / 6.8)) scale(1.2)',
                },
                [theme.breakpoints.down(600)]: {
                  transform: `translateY(-${mobAnimationStart}px)`,
                },
              }}
            >
              <img width={290} src={phone_1} alt="phone"></img>
              <Typography>Manage</Typography>
            </Box>
            <Box
              sx={{
                padding: '40px',
                display: 'flex',
                flexWrap: 'wrap',
                gap: '40px',
                justifyContent: 'center',
              }}
            >
              <Box>
                <img width={290} src={phone_2} alt="phone"></img>
                <Typography>Trade</Typography>
              </Box>
              <Box>
                <img width={290} src={phone_3} alt="phone"></img>
                <Typography>Socialize</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          className="desktop_phone_animation_wrapper"
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Avenir600',
              fontSize: '64px',
              color: 'var(--white)',
              textAlign: 'center',
              maxWidth: '816px',
              marginBottom: '40px',
              marginTop: 'calc(100vh - 90px + 140px)',
            }}
          >
            Unlock the real power of the blockchain
          </Typography>
          <Box
            sx={{
              display: 'flex',
              maxWidth: '1616px',
              padding: '0 20px',
              gap: '80px',
              '& .wrapper': {
                '& img': {
                  maxHeight: '70vh',
                  objectFit: 'contain',
                  width: '100%',
                },
                '& p': {
                  fontFamily: 'Avenir600',
                  fontSize: '32px',
                  color: 'var(--white)',
                  textAlign: 'center',
                  marginTop: '40px',
                  transform: 'translateY(-50px)',
                  opacity: 0,
                  display: 'block',
                },
              },
            }}
          >
            <Box className="wrapper">
              <Box
                className="desktop_left_phone_animation"
                sx={{
                  transform: 'translateX(450px)',
                  opacity: 0,
                }}
              >
                <img
                  style={{
                    opacity: 'inherit',
                  }}
                  src={phone_2}
                  alt="phone"
                ></img>
              </Box>
              <Typography className="desktop_text_animation">Trade</Typography>
            </Box>
            <Box className="wrapper">
              <Box
                className="desktop_phone_animation"
                sx={{
                  zIndex: 9,
                  position: 'relative',
                  transform: 'translateY(calc(-100vh + 100vh / 9)) scale(1.42)',
                  '@media(max-width: 1700px)': {
                    transform: 'translateY(calc(-100vh + 100vh / 9 - 10px)) scale(1.42)',
                  },
                  '@media(max-width: 1600px)': {
                    transform: 'translateY(calc(-100vh + 100vh / 9 - 20px)) scale(1.42)',
                  },
                  '@media(max-width: 1500px)': {
                    transform: 'translateY(calc(-100vh + 100vh / 9 - 30px)) scale(1.42)',
                  },
                  '@media(max-width: 1400px)': {
                    transform: 'translateY(calc(-100vh + 100vh / 9 - 40px)) scale(1.42)',
                  },
                  '@media(max-width: 1300px)': {
                    transform: 'translateY(calc(-100vh + 100vh / 9 - 60px)) scale(1.42)',
                  },
                  '@media(max-width: 1200px)': {
                    transform: 'translateY(calc(-100vh + 100vh / 9 - 70px)) scale(1.42)',
                  },
                }}
              >
                <img src={phone_1} alt="phone"></img>
              </Box>
              <Typography className="desktop_text_animation">Manage</Typography>
            </Box>
            <Box className="wrapper">
              <Box
                className="desktop_right_phone_animation"
                sx={{
                  transform: 'translateX(-450px)',
                  opacity: 0,
                }}
              >
                <img
                  style={{
                    opacity: 'inherit',
                  }}
                  src={phone_3}
                  alt="phone"
                ></img>
              </Box>
              <Typography className="desktop_text_animation">Socialize</Typography>
            </Box>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          height: '160px',
          width: '100%',
          marginTop: isMobile ? '60px' : '140px',
          background:
            'linear-gradient(180deg, rgba(226, 15, 0, 0.00) 0%, rgba(226, 49, 0, 0.25) 25%, rgba(227, 82, 0, 0.50) 50%, rgba(227, 116, 0, 0.75) 75%, #E39600 100%)',
        }}
      ></Box>
    </Box>
  );
}

export default UnlockThePower;
